import React from 'react'
import styled from 'styled-components'

import { Btn, Icon } from '../../UI'

const Wrap = styled.div`
	display: flex;
	align-items: center;
	color: ${props => props.theme.colors.black.val};
	transition: opacity ${props => props.theme.times.tranS};
	position: absolute;
	z-index: 14;
	background: ${props => props.theme.colors.white.val};
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	width: 85vw;
	${props => props.theme.media.sdesk} {
		top: ${props => props.theme.sizes.header.sdesk.num * 0.5}px;
		transform: translate3d(-50%, -50%, 0);
		width: 33.33vw;
	}
	button {
		border: none;
		outline: none;
		.icon {
			color: ${props => props.theme.colors.black.val};
		}
	}
	input {
		height: 100%;
		width: 100%;
		padding: 0.5em 1em;
		border: none;
		background: none;
	}
`

const SearchInput = ({ updateResultsHandler, closeSearchHandler, isOpen }) => {
	const handleChange = e => {
		const str = e.target.value

		updateResultsHandler(str)
	}
	return (
		<Wrap style={{ opacity: isOpen ? 1 : 0, pointerEvents: isOpen ? 'initial' : 'none' }}>
			<Btn onClick={closeSearchHandler} title='Close global search input'>
				<Icon type='chevron' flipH />
			</Btn>
			<input
				className='search-input'
				onChange={handleChange}
				placeholder='Search...'
				title='Search site'
			/>
		</Wrap>
	)
}
SearchInput.propTypes = {}
export default SearchInput
