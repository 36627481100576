import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Header from '../global/SiteHeader'
import Footer from '../global/SiteFooter'

const Wrap = styled.div``

const Page = ({ children, pageData, className, ...props }) => {
	const { pageTitle, title, name } = pageData
	const heading = pageTitle || name || title
	return (
		<Wrap className={`page page-${heading}${className ? ` ${className}` : ''}`} {...props}>
			<Header />
			{children}
			<Footer />
		</Wrap>
	)
}
Page.propTypes = {
	pageData: PropTypes.shape({
		pageTitle: PropTypes.string,
		title: PropTypes.string,
		name: PropTypes.string,
	}),
}
export default Page
