import { useStaticQuery, graphql } from 'gatsby'
import sortBy from 'lodash/sortBy'

import { isProxy } from '../../../utils'

const parseSortIdx = idx => (idx === 'Tier One' ? 1 : idx === 'Tier Two' ? 2 : 3)
export default () => {
	const { navData, campusData, blogData, globalData } = useStaticQuery(graphql`
		query mainNavQuery {
			navData: allContentfulNavContainer {
				edges {
					node {
						id: contentful_id
						sortIdx
						heading
						title
						groups {
							id: contentful_id
							title
							link
							icon {
								type
							}
							pages {
								... on ContentfulLink {
									internal {
										type
									}
									id: contentful_id
									name: title
									link
								}
								... on ContentfulBlog {
									id: contentful_id
									title: breadcrumb
									slug
									group1: pagegroup {
										id: contentful_id
										title: breadcrumb
										slug
									}
									group2: pagegroup2 {
										id: contentful_id
										title: breadcrumb
										slug
										group: pagegroup {
											id: contentful_id
											title: breadcrumb
											slug
										}
									}
								}
								... on ContentfulPage {
									id: contentful_id
									title: breadcrumb
									slug
									group1: pagegroup {
										id: contentful_id
										title: breadcrumb
										slug
									}
									group2: pagegroup2 {
										id: contentful_id
										title: breadcrumb
										slug
										group: pagegroup {
											id: contentful_id
											title: breadcrumb
											slug
										}
									}
								}
								... on ContentfulProfile {
									id: contentful_id
									title: breadcrumb
									slug
									group1: pagegroup {
										id: contentful_id
										title: breadcrumb
										slug
									}
									group2: pagegroup2 {
										id: contentful_id
										title: breadcrumb
										slug
										group: pagegroup {
											id: contentful_id
											title: breadcrumb
											slug
										}
									}
								}
								... on ContentfulPageGroup {
									id: contentful_id
									title: breadcrumb
									slug
								}
								... on ContentfulPageGroup2 {
									id: contentful_id
									title: breadcrumb
									slug
									group: pagegroup {
										id: contentful_id
										title: breadcrumb
										slug
									}
								}
							}
						}
					}
				}
			}
			campusData: allContentfulCampus {
				edges {
					node {
						id: contentful_id
						slug
						title: breadcrumb
					}
				}
			}
			blogData: allContentfulBlog(sort: { fields: publishDate, order: DESC }, limit: 4) {
				edges {
					node {
						id: contentful_id
						slug
						title: breadcrumb
						group1: pagegroup {
							id: contentful_id
							title: breadcrumb
							slug
						}
						group2: pagegroup2 {
							id: contentful_id
							title: breadcrumb
							slug
							group: pagegroup {
								id: contentful_id
								title: breadcrumb
								slug
							}
						}
					}
				}
			}
			globalData: contentfulGlobalInfo(contentful_id: { eq: "nKrVdFFy9DjVaFnDRcnOB" }) {
				programs: menuProgramLinks {
					id: contentful_id
					title: breadcrumb
					slug
					group1: pagegroup {
						id: contentful_id
						title: breadcrumb
						slug
					}
					group2: pagegroup2 {
						id: contentful_id
						title: breadcrumb
						slug
						group: pagegroup {
							id: contentful_id
							title: breadcrumb
							slug
						}
					}
				}
			}
		}
	`)

	const navItems = sortBy(
		navData.edges
			.filter(({ node }) => !isProxy(node.title))
			.map(({ node }) => {
				return {
					...node,
					groups: node.groups.map(({ pages, ...group }) => ({
						...group,
						pages: pages?.map(({ title, name, ...page }) => ({
							...page,
							title: title || name,
						})),
					})),
				}
			}),
		({ sortIdx }) => parseSortIdx(sortIdx)
	)

	const campuses = campusData.edges
		.filter(({ node }) => !isProxy(node.slug))
		.map(({ node }) => node)

	const blogPosts = blogData.edges.filter(({ node }) => !isProxy(node.slug)).map(({ node }) => node)

	const { programs } = globalData || {}

	return {
		navItems,
		campuses,
		blogPosts,
		programs,
	}
}
