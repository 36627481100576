/* eslint-disable array-callback-return */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import isEmpty from 'lodash/isEmpty'
import toLower from 'lodash/toLower'
import includes from 'lodash/includes'
import { Link, Icon } from '../../UI'
import { useStoreState } from '../../../store'
import { useState } from '../../../utils'

/* LINE 21 REPLACED background: ${props => props.theme.colors.white.val}; WITH background: #e3ebf2; */
const Wrap = styled.div`
	position: absolute;
	top: ${props => props.theme.sizes.header.mobile.px};
	left: 0;
	width: 100vw;
	height: calc(100vh - ${props => props.theme.sizes.header.mobile.px});
	color: ${props => props.theme.colors.black.val};
	z-index: 15;
	background: #e3ebf2;
	overflow: hidden;
	transition: max-height ${props => props.theme.times.tranS};
	${props => props.theme.media.sdesk} {
		top: ${props => props.theme.sizes.header.sdesk.px};
		width: 60vw;
		left: 50%;
		transform: translateX(-50%);
		height: 50vh;
	}
	.inner-wrap {
		height: 100%;
		overflow: auto;
		${props => props.theme.media.sdesk} {
			overflow: hidden;
		}
	}
	ul {
		li {
			list-style: none;
			display: flex;
			align-items: center;
			padding-left: 0.5em;
			&:hover {
				background: ${props => props.theme.colors.grey.light(5).val};
			}
			.icon {
				color: ${props => props.theme.colors.grey.val};
				margin-right: 0.5em;
			}
			.link {
				color: ${props => props.theme.colors.black.val};
				padding: 1em 0;
				flex-grow: 1;
			}
			.no-results {
				/* font-style: italic; */
				color: ${props => props.theme.colors.grey.val};
				padding: 1em;
			}
		}
	}
`

const SearchPane = ({ searchStr, isOpen: isSearchOpen }) => {
	const searchIndex = useStoreState(state => state.Meta.searchIndex)
	const [isOpen, setIsOpen] = useState(isSearchOpen, 'isOpen')

	const results = searchIndex.filter(({ indexStr }) => {
		if (!searchStr || isEmpty(searchStr) || searchStr.length < 3) {
			if (isOpen) setIsOpen(false)
		} else {
			if (!isOpen) setIsOpen(true)
			return includes(indexStr, toLower(searchStr))
		}
	})

	return (
		<Wrap
			className='search-results-pane'
			style={{ maxHeight: isOpen && isSearchOpen ? 800 : 0 }}
			role='dialog'
		>
			<div className='inner-wrap'>
				<ul>
					{results && !isEmpty(results) ? (
						results.map(({ title, uri }) => (
							<li key={`${uri}-${title}`}>
								<Icon type='search' />
								<Link to={uri} noStyles>
									{title}
								</Link>
							</li>
						))
					) : (
						<li>
							<i className='no-results'>No Results</i>
						</li>
					)}
				</ul>
			</div>
		</Wrap>
	)
}
SearchPane.propTypes = {
	searchStr: PropTypes.string,
	isOpen: PropTypes.bool,
	isSearchOpen: PropTypes.bool,
}
export default SearchPane
