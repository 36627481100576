import styled from 'styled-components'

export default styled.header`
height: 35px;
background: ${props => props.theme.colors.slate.val};
align-items: center;
padding: 0 ${props => props.theme.sizes.gutter.mobile.px};
${props => props.theme.media.sdesk} {
  padding: 0 ${props => props.theme.sizes.gutter.sdesk.px};
  height: 81px;
}
.centered {
  ${props => props.theme.media.sdesk} {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
}
.branding {
  display: block;
  height: 19px;
  width: 30px;
  ${props => props.theme.media.sdesk} {
    z-index: 2;
    height: auto;
    width: auto;
    transform: translateY(3px);
  }
  .site-logo-mobile {
    ${props => props.theme.media.sdesk} {
      display: none;
    }
  }
  .site-logo-desk {
    display: none;
    ${props => props.theme.media.sdesk} {
      display: block;
      height: 69px;
    }
  }
  /* .desk-titles {
    display: none;
    ${props => props.theme.media.sdesk} {
      display: inline-block;
      margin-left: 0.8rem;
      color: ${props => props.theme.colors.slate.light(2).val};
      font-weight: 300;
      font-size: 1.8em;
    }
  } */
  svg {
    height: 100%;
  }
}
& > .flex {
  height: 100%;
  flex-grow: 1;
  ${props => props.theme.media.sdesk} {
    margin-left: auto;
    justify-content: flex-end;
    align-items: center;
  }
}
.search-bar {
  margin-left: 1em;
  ${props => props.theme.media.sdesk} {
    margin-left: 2em;
  }
}
.mobile-nav-ico {
  margin-left: 0.8em;
}
.right-nav-bar {
  display: flex;
  margin-left: auto;
  justify-content: center;
  ${props => props.theme.media.sdesk} {
    z-index: 2;
  }
  & > .link, .contact-menu-btn {
    text-transform: uppercase;
      color: ${props => props.theme.colors.white.val};
        border-bottom: solid 1px transparent;
      &:hover {
          border-bottom: solid 1px ${props => props.theme.colors.white.val};
      }
    ${props => props.theme.media.sdesk} {
      margin: 0;
      margin-left: 1em;
      display: block;
    }
    span {
      ${props => props.theme.media.sdesk} {
        font-size: 0.9rem;
        font-weight: 500;
      }
    }
  }
  .__hide-mobile {
    display: none;
    ${props => props.theme.media.sdesk} {
      display: block;
    }
  }
  .login-link {
    .icon {
      margin-right: 0.5em;
      ${props => props.theme.media.sdesk} {
        transform: translateY(-0.08em);
        height: 0.9rem;
      }
      svg {
        height: 100%;
        width: auto;
      }
	}
	.text {
		display: none;
		${props => props.theme.media.sdesk} {
			text-transform: uppercase;
			display: inline-block;
			margin-left: 0.5em;
			font-size: 0.9rem;
			font-weight: 500;
		}
	}
  }
}
`
