import React from 'react'
import styled from 'styled-components'
import { Btn, Icon } from '../../UI'

const Button = styled(Btn)`
	background: ${props => props.theme.colors.slate.val};
	color: ${props => props.theme.colors.white.val};
	display: grid;
	place-items: center;
	height: 100%;
	width: 100%;
	${props => props.theme.media.sdesk} {
		height: 35px;
		width: 35px;
	}
	.icon {
		svg {
			height: 100%;
			width: auto;
		}
	}
`
const SearchBtn = props => (
	<Button className='ctrl-ico' {...props} title='Open global search input'>
		<Icon type='search' />
	</Button>
)
SearchBtn.propTypes = {}
export default SearchBtn
