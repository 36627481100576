import React, { useEffect } from 'react'
import styled from 'styled-components'

import { Link, Icon } from '../../UI'
import { useStoreState, useStoreActions } from '../../../store'

const Button = styled.a`
	margin: 0;
	padding: 0;
	border-bottom: solid 1px transparent;
	&:hover {
		color: ${props => props.theme.colors.white.val};
		border-bottom: solid 1px ${props => props.theme.colors.white.val};
	}
	${props => props.theme.media.sdesk} {
		margin: 0;
		padding: 0;
	}
	span {
		line-height: normal;
	}
`

const Menu = styled.div`
	color: ${props => props.theme.colors.white.val};
	z-index: 12;
	overflow: hidden;
	transition: max-height ${props => props.theme.times.tranM};
	position: absolute;
	top: ${props => props.theme.sizes.header.mobile.px};
	right: 0;
	background: #e3ebf2;
	width: 100vw;
	max-width: 450px;
	${props => props.theme.media.sdesk} {
		top: ${props => props.theme.sizes.header.sdesk.px};
	}
	nav {
		padding: 1.5em;
		li {
			list-style: none;
			display: block;
			padding: 0.5em 0;
			p {
				color: ${props => props.theme.colors.black.val};
				text-transform: uppercase;
				font-size: 10px;
				margin: 0;
				padding: 0;
			}
			.link {
				margin-top: -3px;
				display: block;
				color: ${props => props.theme.colors.orange.val};
				.icon {
					height: 0.8em;
					vertical-align: center;
					display: inline-flex;
					align-items: center;
					margin-left: 0.5em;
				}
			}
		}
	}
`

export const ContactMenu = () => {
	const toggle = useStoreActions(actions => actions.View.toggleLoginMenu)
	const show = useStoreState(state => state.View.isLoginMenuOpen)

	useEffect(() => {
		const closeContactMenu = () => toggle(false)

		if (show) {
			document.addEventListener('click', closeContactMenu)
		} else {
			document.removeEventListener('click', closeContactMenu)
		}

		return () => document.removeEventListener('click', closeContactMenu)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [show])

	return (
		<>
			<Menu style={{ maxHeight: show ? 700 : 0 }}>
				<nav>
					<li>
						<p>Career starters login here</p>
						<Link to='/career-starter-contact' noStyles>
							I'm here to start my career
							<Icon type='arrow' />
						</Link>
					</li>
					<li>
						<p>Professionals login here</p>
						<Link to='/professional-contact' noStyles>
							I'm here to advance my career
							<Icon type='arrow' />
						</Link>
					</li>
					<li>
						<p>Enterprise leaders login here</p>
						<Link to='/enterprise-contact' noStyles>
							I'm here for enterprise training solutions
							<Icon type='arrow' />
						</Link>
					</li>
					<li>
						<p>General Contact</p>
						<Link to='/contact' noStyles>
							Contact ACI Learning
							<Icon type='arrow' />
						</Link>
					</li>
				</nav>
			</Menu>
		</>
	)
}

export const ContactNavBtn = ({ className }) => {
	const toggle = useStoreActions(actions => actions.View.toggleLoginMenu)

	return (
		<Button
			aria-current='page'
			className={`contact-menu-btn link${className ? ` ${className}` : ''}`}
			onClick={() => toggle()}
		>
			<span className='text'>Get Started</span>
		</Button>
	)
}
