import React from 'react'

import { Link } from '../../UI'
import Logo from '../../../images/Logo.sm'
import DeskLogo from '../../../images/Logo.desk.branded'
import { useStoreActions } from '../../../store'

const Branding = () => {
	const updateDeskNav = useStoreActions(actions => actions.View.updateNavPane)
	return (
		<Link className='branding' to='/' onClick={() => updateDeskNav(null)} noStyles>
			<Logo className='site-logo-mobile' />
			<DeskLogo />
		</Link>
	)
}
Branding.propTypes = {}
export default Branding
