import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import get from 'lodash/get'

import { Link, Icon } from '../../UI'
import { parsePageURI } from '../../../utils'

const Column = styled.div`
	padding: 2rem;
	${props => props.theme.media.sdesk} {
		padding: 2rem 0;
		padding-right: 2rem;
	}
	.column-title {
		font-weight: 200;
		font-size: 1.4rem;
		margin-bottom: 1rem;
		.icon {
			height: 30px;
			width: 30px;
			margin-right: 10px;
			svg {
				width: 100%;
				height: auto;
			}
		}
	}
	& > nav {
		padding-left: 40px;
		li {
			list-style: none;
			margin-bottom: 0.5em;
			a {
				font-size: 1.2rem;
				font-weight: 400;
			}
		}
	}
`

const parseNavUrl = page => (get(page, 'link') ? page.link : parsePageURI(page))

const NavColumn = ({ group }) => {
	const { title, pages, icon, link } = group
	return (
		<Column>
			<header className='column-title'>
				{link ? (
					<Link to={link} noStyles>
						{get(icon, 'type') ? (
							<Icon type={icon.type} className='icon' />
						) : (
							<i className='icon placeholder' />
						)}
						{title}
					</Link>
				) : (
					<div className='title'>
						{get(icon, 'type') ? (
							<Icon type={icon.type} className='icon' />
						) : (
							<i className='icon placeholder' />
						)}
						{title}
					</div>
				)}
			</header>
			{pages && pages.length ? (
				<nav>
					{pages.map(({ id, ...li }) => (
						<li key={id}>
							<Link to={parseNavUrl(li)} noStyles>
								{li.title}
							</Link>
						</li>
					))}
				</nav>
			) : null}
		</Column>
	)
}
NavColumn.propTypes = {
	group: PropTypes.shape({
		title: PropTypes.string,
		link: PropTypes.string,
		icon: PropTypes.shape({
			type: PropTypes.string,
		}),
		pages: PropTypes.arrayOf(
			PropTypes.shape({
				title: PropTypes.string,
				link: PropTypes.string,
			})
		),
	}),
}
export default NavColumn
