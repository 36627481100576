import React, { useEffect } from 'react'
// import PropTypes from 'prop-types'
import styled from 'styled-components'
import toLower from 'lodash/toLower'
import includes from 'lodash/includes'
import get from 'lodash/get'
import { useStoreState, useStoreActions } from '../../../store'
import { parsePageURI, useState } from '../../../utils'
import { Link, H4, H5 } from '../../UI'
import useNav from './useNav'
import ITProLogo from '../../../images/Logo.ITPro'
import MistiLogo from '../../../images/Logo.Misti'
import LQLogo from '../../../images/Logo.LQ'

import NavColumn from './NavColumn'

const Panes = styled.div`
	position: absolute;
	top: ${props => props.theme.sizes.header.sdesk.px};
	right: 0;
	left: 0;
	bottom: 0;
	z-index: 10;
	.nav-pane {
		${props => props.theme.media.sdesk} {
			padding: 0 ${props => props.theme.sizes.gutter.sdesk.px};
		}
		position: absolute;
		overflow: hidden;
		transition: all ${props => props.theme.times.tranM};
		background: ${props => props.theme.colors.navPaneBg.val};
		color: ${props => props.theme.colors.white.val};
		z-index: 1;
		width: 100%;
		a {
			color: inherit;
		}
		.pane-content {
			padding-bottom: 3em;
			min-height: 50vh;
		}
		.pane-title {
			font-size: 1.5rem;
			font-weight: 700;
			padding: 2em 3em;
			text-transform: capitalize;
			${props => props.theme.media.sdesk} {
				padding: 2em 0;
			}
		}
		main {
			flex-grow: 1;
		}
		aside {
			min-width: 25vw;
			border-left: solid 1px ${props => props.theme.colors.white.val};
			margin: 50px;
			margin-bottom: 0;
			padding: 0 0 50px 100px;
			h5 {
				font-weight: 200;
				font-size: 1.4rem;
			}
			.campuses {
				margin-bottom: 3em;
			}
			nav {
				margin: 30px 0;
				li {
					list-style: none;
					margin-bottom: 0.5em;
					a {
						font-size: 1.2rem;
						font-weight: 400;
					}
				}
				&.faq {
					margin-top: 70px;
					margin-bottom: 0;
					li {
						list-style: none;
						margin-bottom: 0.5em;
						a {
							font-size: 1.2rem;
							font-weight: 400;
						}
					}
				}
			}
			section.logo {
				display: flex;
				flex-direction: column;
				height: 100%;
				justify-content: space-between;
				padding-top: 1.5rem;
			}
			.logo-link {
				width: 100%;
				display: flex;
				justify-content: center;
				margin-bottom: 1rem;
				&:last-child {
					margin-bottom: 0;
				}
				svg {
					display: block;
					height: auto;
					width: 120px;
				}
			}
			.lq-link {
				svg {
					width: 175px;
				}
			}
			.misti-link {
				svg {
					width: 150px;
				}
			}
		}
	}
	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 0;
	}
`

const Pane = ({ tier, campuses, posts, isOpen }) => {
	const { heading, groups } = tier
	const [maxHeight, setMaxHeight] = useState(null, 'maxHeight')
	const isAbout = includes(toLower(heading), 'about')
	const isResources = includes(toLower(heading), 'resources')

	useEffect(() => {
		const updateHeight = () => setMaxHeight(window.innerHeight)
		if (typeof window !== 'undefined') {
			window.addEventListener('resize', updateHeight)
			if (!maxHeight) setMaxHeight(window.innerHeight)
		}
		return () => window.removeEventListener('resize', updateHeight)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	return (
		<div
			className='nav-pane'
			style={{ maxHeight: isOpen ? maxHeight || 600 : 0, pointerEvents: isOpen ? 'auto' : 'none' }}
		>
			<div className='pane-content flex-desk'>
				<main>
					<H4 className='pane-title'>{heading}</H4>
					<div className='columns flex-desk'>
						{groups.map(({ id, ...group }) => (
							<NavColumn key={id} group={group} />
						))}
					</div>
				</main>
				{isAbout ? (
					<aside className='fixed-links'>
						<section className='campuses'>
							<H5>Learning Hubs</H5>
							<nav>
								{campuses.map(campus => (
									<li key={campus.id}>
										<Link to={`/learning-hubs/${campus.slug}`} noStyles>
											{campus.title}
										</Link>
									</li>
								))}
							</nav>
						</section>
					</aside>
				) : isResources ? (
					<aside className='fixed-links'>
						<section className='posts'>
							<H5>Recent Articles</H5>
							<nav>
								{posts.map(post => (
									<li key={post.id}>
										<Link to={parsePageURI(post)} noStyles>
											{post.title}
										</Link>
									</li>
								))}
							</nav>
						</section>
						{/* <section className='additional-links'>
							<nav className='faq'>
								<li>
									<Link to='/faq' noStyles>
										FAQs
									</Link>
								</li>
							</nav>
						</section> */}
					</aside>
				) : (
					<aside className='fixed-links'>
						<section className='logo'>
							<Link
								className='it-pro-link logo-link'
								to='https://www.itpro.tv/?utm_campaign=ITProTVLaunch&utm_source=Website&utm_content=IconTopNav'
								noStyles
							>
								<ITProLogo />
							</Link>
							<Link className='lq-link logo-link' to='https://www.leaderquestonline.com/' noStyles>
								<LQLogo />
							</Link>
							<Link className='misti-link logo-link' to='https://www.misti.com/' noStyles>
								<MistiLogo />
							</Link>
						</section>
					</aside>
				)}
			</div>
		</div>
	)
}

const NavPanes = () => {
	const currentOpenPane = useStoreState(state => state.View.navPane)
	const updatePane = useStoreActions(actions => actions.View.updateNavPane)
	const { navItems, campuses, blogPosts } = useNav()
	useEffect(() => {
		const closeNav = e => {
			if (get(e, 'target.attributes.data-pane')) return
			updatePane(null)
		}

		if (currentOpenPane) {
			document.addEventListener('click', closeNav)
		} else {
			document.removeEventListener('click', closeNav)
		}

		return () => document.removeEventListener('click', closeNav)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentOpenPane])
	return (
		<>
			<Panes
				className='nav-panes'
				onClick={() => updatePane(null)}
				role='button'
				style={{ pointerEvents: currentOpenPane ? 'auto' : 'none' }}
			>
				{navItems.map(({ id, ...tier }) => (
					<Pane
						key={id}
						tier={tier}
						isOpen={id === currentOpenPane}
						campuses={campuses}
						posts={blogPosts}
					/>
				))}
			</Panes>
		</>
	)
}
NavPanes.propTypes = {}
export default NavPanes
