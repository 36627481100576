import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { parsePageURI, socIconFromLink } from '../../../utils'
import { useStoreState } from '../../../store'

import { Link, Icon } from '../../UI'
import ContactInfo from '../../common/ContactInfo'

const Footer = styled.footer`
	//border-top: 20px solid ${props => props.theme.colors.slate.val};
	padding: 30px ${props => props.theme.sizes.gutter.mobile.px};
	${props => props.theme.media.sdesk} {
		padding: 30px ${props => props.theme.sizes.gutter.sdesk.px};
	}
	background: linear-gradient(
		to bottom,
		${props => props.theme.colors.slate.dark('2').val} 0%,
		${props => props.theme.colors.slate.val} 100%
	);
	color: #7b98b5;
	a {
		color: #7b98b5;
		&:hover {
			color: ${props => props.theme.colors.orange.val};
			border: none;
		}
	}
	nav {
		margin-bottom: 0;
		justify-content: center;
		flex-direction: column;
		${props => props.theme.media.sdesk} {
			flex-direction: row;
			margin-bottom: 20px;
		}
		&.links {
			.link {
				text-transform: uppercase;
			}
		}
		a {
			font-size: 0.9rem;
			margin: 10px 0;
			${props => props.theme.media.sdesk} {
				margin: 0 35px;
			}
		}
	}
	.flex-wrap {
		display: flex;
		flex-direction: column-reverse;
		${props => props.theme.media.sdesk} {
			flex-direction: row;
			justify-content: center;
			align-items: center;
		}
	}
	.copyright {
		font-size: 0.9rem;
		text-align: left;
    text-align: center;
	}
	.social {
		margin: 20px 0;
    text-align:center;
		${props => props.theme.media.sdesk} {
			margin: 0 0 0 20px;
		}
		a {
			margin: 0 10px;
			&:nth-of-type(1) {
				margin: 0 10px 0 0;
			}
			&:last-child {
				margin-right: 0;
			}
		}
	}
  .campuses{
    h3{
      font-size:2rem;
      font-weight:800;
      text-align:center;
      padding:20px 0 60px 0;
    }
    .campus-grid {
		display: flex;
		flex-direction: column-reverse;
		${props => props.theme.media.tablet} {
			grid-template-columns: repeat(auto-fill, 50%);
			display: grid;
			align-content: center;
		}
		${props => props.theme.media.sdesk} {
			grid-template-columns: repeat(auto-fill, 20%);
		}
    .hub{
      text-align: center;
      p{
        color: #7b98b5;
      }
    }   
  }
}
`

const SiteFooter = () => {
	const { data, siteInfo } = useStaticQuery(graphql`
		query siteFooterQuery {
			siteInfo: contentfulGlobalInfo(contentful_id: { eq: "nKrVdFFy9DjVaFnDRcnOB" }) {
				siteTitle
			}
			data: contentfulGlobalFooter(contentful_id: { eq: "1xp3iLqoVPIYGZZUnuntdM" }) {
				socData: footerSocialLinks {
					id: contentful_id
					accounts {
						id: contentful_id
						link
						title
					}
				}
				links {
					... on ContentfulLink {
						internal {
							type
						}
						id: contentful_id
						title
						link
					}
					... on ContentfulBlog {
						id: contentful_id
						title: breadcrumb
						slug
						group1: pagegroup {
							id: contentful_id
							title: breadcrumb
							slug
						}
						group2: pagegroup2 {
							id: contentful_id
							title: breadcrumb
							slug
							group: pagegroup {
								id: contentful_id
								title: breadcrumb
								slug
							}
						}
					}
					... on ContentfulPage {
						id: contentful_id
						title: breadcrumb
						slug
						group1: pagegroup {
							id: contentful_id
							title: breadcrumb
							slug
						}
						group2: pagegroup2 {
							id: contentful_id
							title: breadcrumb
							slug
							group: pagegroup {
								id: contentful_id
								title: breadcrumb
								slug
							}
						}
					}
					... on ContentfulProfile {
						id: contentful_id
						title: breadcrumb
						slug
						group1: pagegroup {
							id: contentful_id
							title: breadcrumb
							slug
						}
						group2: pagegroup2 {
							id: contentful_id
							title: breadcrumb
							slug
							group: pagegroup {
								id: contentful_id
								title: breadcrumb
								slug
							}
						}
					}
					... on ContentfulPageGroup {
						id: contentful_id
						title: breadcrumb
						slug
					}
					... on ContentfulPageGroup2 {
						id: contentful_id
						title: breadcrumb
						slug
						group: pagegroup {
							id: contentful_id
							title: breadcrumb
							slug
						}
					}
				}
				legalLinks {
					... on ContentfulLink {
						internal {
							type
						}
						id: contentful_id
						title
						link
					}
					... on ContentfulPage {
						id: contentful_id
						title: breadcrumb
						slug
						group1: pagegroup {
							id: contentful_id
							title: breadcrumb
							slug
						}
						group2: pagegroup2 {
							id: contentful_id
							title: breadcrumb
							slug
							group: pagegroup {
								id: contentful_id
								title: breadcrumb
								slug
							}
						}
					}
				}
				learningHubs {
					name
					breadcrumb
					contactInfo {
						street
						city
						state
						country
						zip
						phone
					}
				}
			}
		}
	`)
	const hasGlobalNav = useStoreState(state => state.View.hasGlobalNav)
	const { siteTitle } = siteInfo
	const { socData, links, legalLinks, learningHubs } = data
	const { accounts: socLinks } = socData || {}

	return (
		<Footer className='site-footer'>
			{hasGlobalNav ? (
				<>
					<nav className={`flex links`}>
						{links &&
							links.map(({ id, link, title, ...p }) => (
								<Link key={id} noStyles to={link ? link : parsePageURI(p)}>
									{title}
								</Link>
							))}
					</nav>
				</>
			) : (
				<div className='campuses'>
					<h3>ACI Learning Hubs</h3>
					<div className='campus-grid'>
						{learningHubs &&
							learningHubs.map(({ breadcrumb, contactInfo }) => {
								const title = breadcrumb
								return (
									<div key={title} className='hub'>
										<div className='name'>{title}</div>
										<ContactInfo contactInfo={contactInfo} />
									</div>
								)
							})}
					</div>
				</div>
			)}
			<div className={`flex-wrap`}>
				<div className='copyright'>
					{`©${new Date().getFullYear()} ${siteTitle} | `}
					{legalLinks &&
						legalLinks.map(({ id, link, title, ...p }, idx) => (
							<React.Fragment key={id}>
								<Link noStyles to={link ? link : parsePageURI(p)}>
									{title}
								</Link>
								{idx < legalLinks.length - 1 ? ` | ` : null}
							</React.Fragment>
						))}
				</div>
				<div className={`social`}>
					{socLinks &&
						socLinks.map(({ id, link, title }) => (
							<Link key={id} to={link} noStyles title={title}>
								<Icon type={socIconFromLink(link)} />
							</Link>
						))}
				</div>
			</div>
		</Footer>
	)
}
SiteFooter.propTypes = {}
export default SiteFooter
