import React, { useEffect } from 'react'
// import PropTypes from 'prop-types'
import styled from 'styled-components'
import get from 'lodash/get'
import useNav from './useNav'

import { useStoreState, useStoreActions } from '../../../store'
import { Btn, Icon, Link, Small, Accordion, AccordionProvider } from '../../UI'
import { parsePageURI } from '../../../utils'

const Button = styled(Btn)`
	${props => props.theme.media.sdesk} {
		display: none;
	}
`

const Nav = styled.div`
	z-index: 12;
	position: fixed;
	top: ${props => props.theme.sizes.header.mobile.px};
	left: 0;
	width: 100vw;
	height: 100vh;
	background: ${props => props.theme.colors.slate.val};
	color: ${props => props.theme.colors.white.val};
	transition: opacity ${props => props.theme.times.tranM};
	padding: 1.5em;
	${props => props.theme.media.sdesk} {
		display: none;
	}
	.close-btn {
		padding: 0;
		margin-bottom: 2em;
		small {
			font-size: 1rem;
			font-weight: 200;
		}
		.icon {
			height: 1rem;
		}
	}
	.links {
		margin-bottom: 1.5em;
		height: 85vh;
		overflow: auto;
		.ctrl {
			font-size: 1.3rem;
			font-weight: 200;
		}
		.tier {
			border-bottom: solid 1px ${props => props.theme.colors.grey.val};
			padding-bottom: 1em;
			margin-bottom: 1em;
			a {
				padding: 0.5em 0;
				display: block;
			}
		}
		.group-link {
			font-weight: 700;
			font-size: 1.3rem;
			color: ${props => props.theme.colors.white.val};
		}
		.page-link {
			font-weight: 300;
			font-size: 1rem;
			color: ${props => props.theme.colors.white.val};
		}
	}
`

export const MobileNav = () => {
	const show = useStoreState(state => state.View.isMobileNavOpen)
	const toggle = useStoreActions(actions => actions.View.toggleMobileNav)
	const { navItems } = useNav()
	useEffect(() => {
		if (show) document.body.classList.add('no-scroll')
		else document.body.classList.remove('no-scroll')
	}, [show])
	return (
		<AccordionProvider>
			<Nav
				className='mobile-nav'
				style={{ opacity: show ? 1 : 0, pointerEvents: show ? 'initial' : 'none' }}
			>
				<Btn className='close-btn' onClick={() => toggle(false)}>
					<Small>
						<Icon type='x' />
						Close
					</Small>
				</Btn>
				<div className='links'>
					{navItems.map(({ id, title, groups }) => (
						<Accordion key={id} title={title} useChevrons id={id}>
							<nav>
								{groups.map(({ link, pages, ...g }) => (
									<div key={g.id} className='tier'>
										<Link
											to={`${link || ''}`}
											className='group-link'
											onClick={() => toggle(false)}
											noStyles
										>
											{g.title}
										</Link>
										{pages && pages.length ? (
											<div className='page-links'>
												{pages.map(p => (
													<Link
														key={p.id}
														to={get(p, 'link') ? p.link : parsePageURI(p)}
														className='page-link'
														onClick={() => toggle(false)}
														noStyles
													>
														{p.title}
													</Link>
												))}
											</div>
										) : null}
									</div>
								))}
							</nav>
						</Accordion>
					))}
				</div>
			</Nav>
		</AccordionProvider>
	)
}

export const MobileNavBtn = () => {
	const toggle = useStoreActions(actions => actions.View.toggleMobileNav)
	return (
		<Button className='mobile-nav-ico' onClick={() => toggle()}>
			<Icon type='hamburger' />
		</Button>
	)
}
