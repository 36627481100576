import React from 'react'
const MistiLogo = () => (
	<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 318.9 112.95'>
		<g data-name='Layer 2'>
			<g data-name='Layer 1'>
				<polygon
					fill='currentColor'
					points='43.89 70.76 62.01 70.76 62.01 56.91 50.25 65.9 43.89 70.76'
				/>
				<polygon
					fill='currentColor'
					points='35.72 42.09 30.75 70.76 39.67 70.76 61.2 54.21 35.72 42.09'
				/>
				<polygon
					fill='currentColor'
					points='11.08 29.96 22.26 70.76 28.09 70.76 33.27 40.88 11.08 29.96'
				/>
				<polygon fill='currentColor' points='36.17 39.52 62.01 51.9 38.5 26.08 36.17 39.52' />
				<polygon
					fill='currentColor'
					points='0 8.75 0 70.77 19.45 70.77 6.7 25.02 33.72 38.31 36.65 20.49 62.02 48.32 62.02 8.75 0 8.75'
				/>
				<rect fill='currentColor' x='218.19' width='2.36' height='79.52' />
				<path
					fill='currentColor'
					d='M189.89,36.83l-2.8-.36c-9.76-1.26-14.31-4.91-14.31-11.47,0-7.3,5.27-11.83,13.74-11.83a27.56,27.56,0,0,1,14.83,4.49l1.09.71,3-4.68L204.42,13c-4.51-3.23-11.1-5.16-17.63-5.16-11.9,0-19.59,6.87-19.59,17.51,0,9.3,6.2,15,17.93,16.51l2.81.36C198,43.52,202.51,47.14,202.51,54c0,7.63-6,12.37-15.72,12.37a28.84,28.84,0,0,1-15.89-5.11l-.26-.18,0,0-2.26-1.74-3.56,4.63,2.32,1.79.18.1a34.28,34.28,0,0,0,18.8,5.87c13.54,0,21.95-6.92,21.95-18.06,0-9.56-6.13-15.2-18.21-16.78'
				/>
				<polygon
					fill='currentColor'
					points='82.67 8.75 77.06 8.75 77.06 70.77 82.64 70.77 82.64 21.86 100.77 61.68 105.09 61.68 123.24 21.86 123.24 70.77 128.83 70.77 128.83 8.75 123.21 8.75 103.17 53.86 102.88 53.22 82.67 8.75'
				/>
				<polygon
					fill='currentColor'
					points='248.58 70.76 254.17 70.76 254.18 14.11 273.37 14.11 273.37 8.75 229.38 8.75 229.38 14.11 248.58 14.11 248.58 70.76'
				/>
				<rect fill='currentColor' x='145.72' y='8.75' width='5.59' height='62.01' />
				<rect fill='currentColor' x='285.24' y='8.75' width='5.59' height='62.01' />
				<path
					fill='currentColor'
					d='M15.59,98.08a7.1,7.1,0,0,0-.93-2.6c-.46-.69-1-.9-3.15-.9H9.44v14.34c0,2.31.24,2.6,3,2.77v.82H4.08v-.82c2.65-.17,2.89-.46,2.89-2.77V94.58H5.16c-2.42,0-3,.23-3.38.9a9.34,9.34,0,0,0-.93,2.6H0c.15-1.75.29-3.64.35-5.25H.93c.41.67.79.73,1.6.73H14.05a1.55,1.55,0,0,0,1.49-.73h.61c0,1.34.14,3.47.29,5.16Z'
				/>
				<path
					fill='currentColor'
					d='M24.72,103.27a4.84,4.84,0,0,0,3.18-.82,4.17,4.17,0,0,0,1.61-3.61,4,4,0,0,0-4.29-4.32,2.94,2.94,0,0,0-1.58.29c-.2.15-.32.5-.32,1.29v7.17Zm10.73,9.47c-.35,0-.67,0-1,0a5.3,5.3,0,0,1-4.72-2.65c-1-1.43-2.07-3.24-2.95-4.66-.49-.82-1-1.17-2.5-1.17h-.94v4.63c0,2.48.27,2.66,2.6,2.83v.82H18.25v-.82c2.39-.17,2.65-.35,2.65-2.83V97.15c0-2.48-.23-2.57-2.5-2.77v-.82h7.23a8.2,8.2,0,0,1,4.66,1,4.36,4.36,0,0,1,1.93,3.82c0,2.51-1.55,4-3.71,5,.5,1,1.72,2.92,2.6,4.23a24.35,24.35,0,0,0,2.36,3,4.09,4.09,0,0,0,2.1,1.37Z'
				/>
				<path
					fill='currentColor'
					d='M45,96.91H45c-.93,2.51-1.87,5-2.74,7.44h5.36Zm2.85,15.6v-.82c1.84-.23,2.1-.46,1.73-1.6s-1-2.71-1.61-4.49H41.78c-.47,1.31-.9,2.54-1.28,3.76-.64,1.84-.38,2.13,1.81,2.33v.82H36v-.82c1.84-.26,2.25-.44,3.27-3l6-15.3.9-.27c1.81,5,3.76,10.56,5.63,15.63.93,2.5,1.31,2.77,3.21,2.94v.82Z'
				/>
				<path
					fill='currentColor'
					d='M56.65,112.51v-.82c2.39-.17,2.63-.35,2.63-2.83V97.2c0-2.47-.24-2.65-2.63-2.82v-.82h7.73v.82c-2.39.17-2.62.35-2.62,2.82v11.66c0,2.51.23,2.66,2.62,2.83v.82Z'
				/>
				<path
					fill='currentColor'
					d='M86.45,94.38c-1.63.09-2.33.46-2.45,1.75a39.22,39.22,0,0,0-.18,4.89v11.75h-.9L70.5,97.5h-.06v7.31a47.81,47.81,0,0,0,.18,4.93c.15,1.49.82,1.84,2.8,2v.82H66.51v-.82c1.69-.08,2.42-.46,2.56-1.89.09-.91.18-2.31.18-5V98.4c0-2.13-.06-2.24-.58-2.94a3.15,3.15,0,0,0-2.6-1.08v-.82h4.32l12.15,14.63h.09V101a45.41,45.41,0,0,0-.2-4.83c-.15-1.32-.85-1.7-2.86-1.81v-.82h6.88Z'
				/>
				<path
					fill='currentColor'
					d='M88.26,112.51v-.82c2.39-.17,2.63-.35,2.63-2.83V97.2c0-2.47-.24-2.65-2.63-2.82v-.82H96v.82c-2.39.17-2.63.35-2.63,2.82v11.66c0,2.51.24,2.66,2.63,2.83v.82Z'
				/>
				<path
					fill='currentColor'
					d='M118.06,94.38c-1.64.09-2.34.46-2.45,1.75a39.22,39.22,0,0,0-.18,4.89v11.75h-.9L102.11,97.5h-.06v7.31a47.81,47.81,0,0,0,.18,4.93c.14,1.49.81,1.84,2.8,2v.82H98.12v-.82c1.69-.08,2.41-.46,2.56-1.89.09-.91.18-2.31.18-5V98.4c0-2.13-.06-2.24-.59-2.94a3.11,3.11,0,0,0-2.59-1.08v-.82H102l12.16,14.63h.09V101a41.75,41.75,0,0,0-.21-4.83c-.14-1.32-.84-1.7-2.86-1.81v-.82h6.89Z'
				/>
				<path
					fill='currentColor'
					d='M138.73,104.52c-1.75.26-1.87.41-1.87,2.13v2.48a12.77,12.77,0,0,0,.21,2.74,28.71,28.71,0,0,1-6.53,1.08c-6.41,0-10.7-4-10.7-9.77,0-6.76,5.42-10.06,11.14-10.06a24.53,24.53,0,0,1,5.59.85c.09,1,.24,2.62.47,4.55l-.82.17c-.7-3.55-2.91-4.52-5.68-4.52-4.46,0-7.73,3.27-7.73,8.66,0,4.2,2.48,9.1,8.11,9.1a5.82,5.82,0,0,0,2.53-.53c.59-.32.94-.67.94-1.89V107c0-2.1-.21-2.28-3.06-2.51v-.79h7.4Z'
				/>
				<path
					fill='currentColor'
					d='M147.13,112.51v-.82c2.39-.17,2.63-.35,2.63-2.83V97.2c0-2.47-.24-2.65-2.63-2.82v-.82h7.73v.82c-2.39.17-2.63.35-2.63,2.82v11.66c0,2.51.24,2.66,2.63,2.83v.82Z'
				/>
				<path
					fill='currentColor'
					d='M176.93,94.38c-1.64.09-2.34.46-2.45,1.75a39.22,39.22,0,0,0-.18,4.89v11.75h-.9L161,97.5h-.06v7.31a47.81,47.81,0,0,0,.18,4.93c.14,1.49.81,1.84,2.8,2v.82H157v-.82c1.69-.08,2.42-.46,2.56-1.89.09-.91.18-2.31.18-5V98.4c0-2.13-.06-2.24-.59-2.94a3.11,3.11,0,0,0-2.59-1.08v-.82h4.31L173,108.19h.09V101a41.75,41.75,0,0,0-.21-4.83c-.14-1.32-.84-1.7-2.85-1.81v-.82h6.88Z'
				/>
				<path
					fill='currentColor'
					d='M188.71,98c-.52-1.86-1.28-3.87-3.82-3.87a3.05,3.05,0,0,0-3.23,3.2c0,2.13,1.4,3.12,3.7,4.32s5,2.53,5,5.8c0,3.06-2.59,5.5-6.47,5.5a10.34,10.34,0,0,1-2.86-.43,10.91,10.91,0,0,1-1.43-.61,36,36,0,0,1-.78-4.52l.84-.21c.47,1.78,1.78,4.73,4.78,4.73a3.25,3.25,0,0,0,3.5-3.5c0-2.16-1.6-3.18-3.79-4.34-1.86-1-4.81-2.45-4.81-5.75,0-2.8,2.25-5.19,6-5.19a11.79,11.79,0,0,1,3.67.68c.12,1,.26,2.18.49,4Z'
				/>
				<path
					fill='currentColor'
					d='M207.57,98.08a7.1,7.1,0,0,0-.93-2.6c-.47-.69-1-.9-3.15-.9h-2.07v14.34c0,2.31.24,2.6,3,2.77v.82h-8.37v-.82c2.65-.17,2.88-.46,2.88-2.77V94.58h-1.8c-2.42,0-3,.23-3.38.9a9.28,9.28,0,0,0-.94,2.6H192c.14-1.75.29-3.64.35-5.25h.58c.41.67.79.73,1.6.73H206a1.53,1.53,0,0,0,1.48-.73h.62c0,1.34.14,3.47.29,5.16Z'
				/>
				<path
					fill='currentColor'
					d='M210.26,112.51v-.82c2.39-.17,2.62-.35,2.62-2.83V97.2c0-2.47-.23-2.65-2.62-2.82v-.82H218v.82c-2.39.17-2.63.35-2.63,2.82v11.66c0,2.51.24,2.66,2.63,2.83v.82Z'
				/>
				<path
					fill='currentColor'
					d='M235.51,98.08a6.88,6.88,0,0,0-.94-2.6c-.46-.69-1-.9-3.14-.9h-2.07v14.34c0,2.31.23,2.6,3,2.77v.82H224v-.82c2.66-.17,2.89-.46,2.89-2.77V94.58h-1.81c-2.42,0-3,.23-3.38.9a9.34,9.34,0,0,0-.93,2.6h-.85c.15-1.75.29-3.64.35-5.25h.59c.4.67.78.73,1.6.73H234a1.55,1.55,0,0,0,1.49-.73h.61c0,1.34.15,3.47.29,5.16Z'
				/>
				<path
					fill='currentColor'
					d='M258,94.38c-1.75.11-2.42.52-2.57,1.72-.08.78-.14,2-.14,4.72v2.42c0,3.44-.53,5.92-2.22,7.69a7.16,7.16,0,0,1-5.22,2,7.6,7.6,0,0,1-4.66-1.4c-1.75-1.34-2.57-3.53-2.57-6.94V97.32c0-2.62-.23-2.74-2.53-2.94v-.82h7.55v.82c-2.31.14-2.54.32-2.54,2.94V104c0,4.69,1.87,7.37,5.48,7.37,4.2,0,5.51-3.49,5.51-8.31v-2.21a38.85,38.85,0,0,0-.2-4.81c-.18-1.14-.79-1.49-2.83-1.63v-.82H258Z'
				/>
				<path
					fill='currentColor'
					d='M275,98.08a7.1,7.1,0,0,0-.93-2.6c-.47-.69-1-.9-3.15-.9h-2.07v14.34c0,2.31.23,2.6,3,2.77v.82h-8.37v-.82c2.66-.17,2.89-.46,2.89-2.77V94.58h-1.81c-2.42,0-3,.23-3.38.9a9.34,9.34,0,0,0-.93,2.6h-.85c.15-1.75.3-3.64.35-5.25h.59c.4.67.78.73,1.6.73h11.51a1.55,1.55,0,0,0,1.49-.73h.61c0,1.34.15,3.47.29,5.16Z'
				/>
				<path
					fill='currentColor'
					d='M292.45,107.9c-.17,1-.76,3.71-1,4.61H277.38v-.82c2.65-.2,2.89-.43,2.89-2.8V97.29c0-2.62-.24-2.71-2.51-2.91v-.82h13c0,.73.15,2.71.24,4.2l-.85.15a7.43,7.43,0,0,0-.93-2.51c-.41-.61-1.2-.82-3.3-.82h-2.21c-.91,0-1,.06-1,.93v6.62h2.74c2.62,0,2.77-.23,3.14-2.18h.85v5.59h-.85c-.37-2.1-.58-2.27-3.11-2.27h-2.77v5.51c0,1.37.17,2,.7,2.36a6.54,6.54,0,0,0,3,.38c2.21,0,3-.24,3.67-1a8.57,8.57,0,0,0,1.43-2.74Z'
				/>
				<path
					fill='currentColor'
					d='M317.59,18.91a.25.25,0,0,1-.26-.26V12.44h-.05L315,17.21a.38.38,0,0,1-.34.25h-.85c-.15,0-.23-.1-.31-.25l-2.29-4.77h-.05v6.21a.25.25,0,0,1-.26.26h-1.06a.24.24,0,0,1-.25-.26V9a.24.24,0,0,1,.25-.26h1a.37.37,0,0,1,.34.23l3,6.5h.08l3-6.5a.4.4,0,0,1,.36-.23h1a.24.24,0,0,1,.25.26v9.64a.24.24,0,0,1-.25.26Zm-14.38,0a.25.25,0,0,1-.26-.26V10.4a.13.13,0,0,0-.15-.15h-2.71a.24.24,0,0,1-.25-.26V9a.24.24,0,0,1,.25-.26h7.4a.24.24,0,0,1,.25.26v1a.24.24,0,0,1-.25.26h-2.71a.13.13,0,0,0-.15.15v8.25a.25.25,0,0,1-.26.26Z'
				/>
			</g>
		</g>
	</svg>
)
export default MistiLogo
