/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import { Helmet } from 'react-helmet'

function SEO({ lang = 'en', pageData = {} }) {
	const { pageTitle } = pageData

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={pageTitle}
			titleTemplate={`%s | ACI Learning`}
		>
			<meta name='robots' content='noindex' />
		</Helmet>
	)
}

SEO.propTypes = {}

export default SEO
