import React from 'react'
const ITProLogo = () => (
	<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 449.28 347.13'>
		<g data-name='Layer 2'>
			<g data-name='Layer 2'>
				<path fill='currentColor' d='M18.34,344.79H0V274.22H18.34Z' />
				<path fill='currentColor' d='M70.92,344.79H52.58v-55H37.42V274.22H86.08v15.53H70.92Z' />
				<path
					fill='currentColor'
					d='M106.47,274.22h28.08c15.16,0,25.64,7,25.64,23.21,0,16.66-9,24-25,24H124.82v23.4H106.47Zm18.35,32.38h3.08c6.65,0,13.2,0,13.2-8.61,0-8.89-6.08-9-13.2-9h-3.08Z'
				/>
				<path
					fill='currentColor'
					d='M240.76,344.79H217.93l-17.32-27.14h-.18v27.14H182.08V274.22H209.5c13.95,0,24.53,6.64,24.53,21.71,0,9.74-5.43,18.16-15.45,19.94Zm-40.33-38.94h1.78c6,0,12.72-1.12,12.72-8.79s-6.73-8.8-12.72-8.8h-1.78Z'
				/>
				<path
					fill='currentColor'
					d='M330.86,308.19c0,22.84-16.38,38.94-39,38.94s-39-16.1-39-38.94c0-21.34,18.72-36.31,39-36.31S330.86,286.85,330.86,308.19Zm-59,.1c0,12.16,9,21.06,19.93,21.06s19.94-8.9,19.94-21.06c0-9.74-9-18.63-19.94-18.63S271.9,298.55,271.9,308.29Z'
				/>
				<circle fill='currentColor' cx='338.29' cy='339.85' r='4.94' />
				<polygon
					fill='currentColor'
					points='430.57 274.22 417.61 317.09 404.74 274.22 394.13 274.22 386.03 274.22 345.47 274.22 345.47 289.75 360.63 289.75 360.63 344.79 378.97 344.79 378.97 289.75 391.48 289.75 410.76 344.79 424.11 344.79 449.28 274.22 430.57 274.22'
				/>
				<path
					fill='currentColor'
					d='M341.41,145.6c-.05-16.61-.67-32.34-1.76-44.31-1.8-19.74-4.36-27.52-10.54-32-3.66-2.65-12.86-9.3-67.72-10.41l-.16-.27a22.38,22.38,0,0,0-9.8-8.75l31.74-34.21a8.23,8.23,0,0,0,1.9.23A8,8,0,0,0,293.13,8,8.06,8.06,0,0,0,277,8a7.62,7.62,0,0,0,.7,3.22L244.15,46.41a43.63,43.63,0,0,0-9.5-1.11h-.5a45.7,45.7,0,0,0-6.88.65l0,0v0h0l28.09-30.29a8.31,8.31,0,0,0,1.91.23A8,8,0,0,0,265.32,8a8.06,8.06,0,0,0-16.11,0,7.63,7.63,0,0,0,.71,3.22L220.5,42.05,191.08,11.17a7.62,7.62,0,0,0,.7-3.22,8.06,8.06,0,0,0-16.11,0,8,8,0,0,0,8.06,7.94,8.23,8.23,0,0,0,1.9-.23L213.73,46h0a45.94,45.94,0,0,0-6.88-.65h-.5a43.63,43.63,0,0,0-9.5,1.11L163.27,11.17A7.63,7.63,0,0,0,164,8a8.06,8.06,0,0,0-16.11,0,8,8,0,0,0,8,7.94,8.25,8.25,0,0,0,1.91-.23l31.74,34.21a22.41,22.41,0,0,0-9.81,8.75l-.15.27C124.74,60,115.54,66.65,111.89,69.3c-6.19,4.47-8.75,12.25-10.54,32-1.09,12-1.72,27.7-1.77,44.31s.49,32.75,1.51,45.37c1.66,20.57,4.11,27.76,7.28,32.21,5.29,7.44,16,11.72,36,14.32,13.41,1.76,42.08,2.69,62,2.7h.5c4.23,0,8.86,0,13.65-.13-17.77-.32-37.8-1.19-48.36-2.57-19.92-2.6-30.68-6.88-36-14.32-3.17-4.45-5.62-11.64-7.28-32.21-1-12.62-1.56-28.73-1.51-45.37s.68-32.34,1.77-44.31c1.79-19.74,4.35-27.52,10.54-32,3.65-2.65,12.85-9.3,67.71-10.41,0,0,9.82-.2,12.52-.2s13.67.2,13.67.2c54.86,1.11,64.06,7.76,67.72,10.41,6.18,4.47,8.74,12.25,10.54,32,1.09,12,1.71,27.7,1.76,44.31s-.48,32.75-1.5,45.37c-1.66,20.57-4.12,27.76-7.28,32.21-5.3,7.44-16.06,11.72-36,14.32-10.56,1.38-30.58,2.25-48.35,2.57,4.79.08,9.42.13,13.65.13h.5c19.92,0,48.59-.94,62-2.7,19.92-2.6,30.68-6.88,36-14.32,3.16-4.45,5.62-11.64,7.28-32.21C340.93,178.35,341.46,162.24,341.41,145.6Z'
				/>
				<rect fill='currentColor' x='159.4' y='97.13' width='37.99' height='104.53' />
				<polygon
					fill='currentColor'
					points='266.7 201.66 266.7 130.13 287.63 131.1 287.63 97.13 207.78 97.13 207.78 131.1 228.71 130.13 228.71 201.66 266.7 201.66'
				/>
			</g>
		</g>
	</svg>
)
export default ITProLogo
