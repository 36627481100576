import React from 'react'
// import PropTypes from 'prop-types'
import styled from 'styled-components'
import useNav from './useNav'
import { useStoreActions, useStoreState } from '../../../store'

import { Btn } from '../../UI'

const Nav = styled.div`
	display: none;
	${props => props.theme.media.sdesk} {
		display: block;
		button {
			margin: 0 1em;
			text-transform: uppercase;
			font-size: 0.9rem;
			font-weight: 500;
			&:hover {
				color: ${props => props.theme.colors.white.val};
				span {
					border-bottom: solid 1px ${props => props.theme.colors.white.val};
				}
			}
			span {
				border-bottom: solid 1px transparent;
			}
		}
	}
`

const DeskNav = () => {
	const { navItems } = useNav()
	const updateNav = useStoreActions(actions => actions.View.updateNavPane)
	const navState = useStoreState(state => state.View.navPane)

	return (
		<Nav className='site-nav-desk'>
			{navItems.map(({ id, title }) => (
				<Btn key={id} className='tier' onClick={() => updateNav(navState === id ? null : id)}>
					<span data-pane={true}>{title}</span>
				</Btn>
			))}
		</Nav>
	)
}
DeskNav.propTypes = {}
export default DeskNav
