import React from 'react'
const LogoSm = props => (
	<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30.02 18.871' {...props}>
		<defs>
			<style>{`.aci-logo-sm-a{fill:#f16622; opacity:1;}.aci-logo-sm-b{fill:#fff;}`}</style>
		</defs>
		<g transform='translate(-70.482 -68.153)'>
			<rect
				className='aci-logo-sm-a'
				width='3.775'
				height='3.736'
				transform='translate(96.728 68.153)'
			/>
			<path
				className='aci-logo-sm-b'
				d='M82.565,88.57h-3.27l-.159-1.688a6.293,6.293,0,0,1-4.59,1.873c-2.137,0-4.063-1.029-4.063-3.772,0-3.007,2.823-4.406,8.442-4.616v-.475c0-1.478-.717-1.938-2.4-2.058A9.432,9.432,0,0,0,71.223,79l.024-2.217a11.021,11.021,0,0,1,6.119-1.618c3.8,0,5.2,1.424,5.2,4.273Zm-3.8-6.231c-3.778.259-4.787,1.061-4.787,2.355,0,1.137.7,1.681,1.992,1.681a4.715,4.715,0,0,0,2.794-1.035Z'
				transform='translate(0 -1.827)'
			/>
			<path
				className='aci-logo-sm-b'
				d='M100.411,84.824a5.444,5.444,0,0,1-5.58,3.994c-4.1,0-6.823-2.592-6.823-6.876,0-4.1,2.54-6.9,6.638-6.9,3.359,0,4.892,1.56,5.713,4.072l-3.2,1.031c-.529-1.719-1.138-2.539-2.513-2.539-1.85,0-2.83,1.376-2.83,4.338,0,2.989,1.191,4.337,2.937,4.337,1.453,0,2.248-.661,2.83-2.3Z'
				transform='translate(-4.564 -1.793)'
			/>
			<rect
				className='aci-logo-sm-b'
				width='3.775'
				height='13.557'
				transform='translate(96.728 73.256)'
			/>
		</g>
	</svg>
)
export default LogoSm
