import React from 'react'

import Branding from '../../common/Branding'
import { MobileNav, MobileNavBtn, DeskNav, NavPanes } from '../NavMenu'
import { SearchBtn, SearchInput, SearchPane } from '../GlobalSearchBar'
import { ContactMenu, ContactNavBtn } from '../ContactMenu'
import { Link, Icon } from '../../UI'
import { useState } from '../../../utils'
import { useStoreState } from '../../../store'

import Header from './SiteHeader.view'

const SiteHeader = () => {
	const hasGlobalNav = useStoreState(state => state.View.hasGlobalNav)
	const [searchResults, setSearchResults] = useState(null, 'searchResults')
	const [isSearchOpen, setSearchOpen] = useState(false, 'isSearchOpen')
	return (
		<>
			<Header className='site-header flex'>
				<Branding />
				{hasGlobalNav ? <MobileNavBtn /> : null}
				{hasGlobalNav ? (
					<div className='centered flex-desk'>
						<SearchBtn onClick={() => setSearchOpen(s => !s)} />
						<DeskNav />
					</div>
				) : null}
				{hasGlobalNav ? (
					<div className='right-nav-bar'>
						<Link to='https://acilearning.docebosaas.com/learn' className='login-link' noStyles>
							<Icon type='profile' />
							<span>Login</span>
						</Link>
						<Link to='/catalogs/professional' className='__hide-mobile' noStyles>
							<span>Catalog</span>
						</Link>
						<ContactNavBtn className='__hide-mobile' />
					</div>
				) : null}
			</Header>
			{hasGlobalNav ? <MobileNav /> : null}
			{hasGlobalNav ? <ContactMenu /> : null}
			{hasGlobalNav ? <NavPanes /> : null}
			{hasGlobalNav ? (
				<SearchInput
					updateResultsHandler={val => setSearchResults(val)}
					isOpen={isSearchOpen}
					closeSearchHandler={() => setSearchOpen(false)}
				/>
			) : null}
			{hasGlobalNav ? <SearchPane searchStr={searchResults} isOpen={isSearchOpen} /> : null}
		</>
	)
}
SiteHeader.propTypes = {}
export default SiteHeader
