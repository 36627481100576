import React from 'react'

const LogoDesk = () => {
	return (
		<svg
			version='1.1'
			xmlns='http://www.w3.org/2000/svg'
			x='0px'
			y='0px'
			viewBox='0 0 131.602 42.854'
			style={{ enableBackground: 'new 0 0 131.602 42.854' }}
			xmlSpace='preserve'
			className='site-logo-desk'
		>
			<g>
				<path
					className='st0'
					fill='#FFFFFF'
					d='M0.057,35.253C0.028,35.253,0,35.225,0,35.195l0.028-2.462L0,30.37c0-0.027,0.028-0.055,0.057-0.055h0.769
		c0.027,0,0.056,0.028,0.056,0.055l-0.029,2.315l0.029,1.89h2.096c0.027,0,0.056,0.029,0.056,0.057v0.563
		c0,0.03-0.029,0.058-0.056,0.058H0.057z'
				/>
				<path
					className='st0'
					fill='#FFFFFF'
					d='M4.737,35.253c-0.029,0-0.057-0.028-0.057-0.058l0.028-2.447L4.68,30.37c0-0.027,0.028-0.055,0.057-0.055
		h3.168c0.027,0,0.057,0.028,0.057,0.055v0.565c0,0.028-0.03,0.056-0.057,0.056H5.556l-0.022,1.305h2.18
		c0.029,0,0.056,0.028,0.056,0.057v0.564c0,0.029-0.027,0.057-0.056,0.057h-2.18l0.022,1.601h2.406c0.027,0,0.055,0.029,0.055,0.057
		v0.563c0,0.03-0.028,0.058-0.055,0.058H4.737z'
				/>
				<path
					className='st0'
					fill='#FFFFFF'
					d='M13.408,35.252c-0.035,0-0.064-0.021-0.078-0.057l-0.429-1.297h-1.954l-0.424,1.297
		c-0.008,0.036-0.037,0.057-0.071,0.057H9.69c-0.027,0-0.05-0.028-0.036-0.057l1.701-4.826c0.014-0.035,0.043-0.055,0.077-0.055
		h1.039c0.034,0,0.063,0.02,0.077,0.055l1.672,4.826c0.013,0.029-0.007,0.057-0.035,0.057H13.408z M12.66,33.178l-0.748-2.258
		l-0.732,2.258H12.66z'
				/>
				<path
					className='st0'
					fill='#FFFFFF'
					d='M18.287,34.264l-0.614-0.924h-0.889l0.015,1.855c0,0.029-0.029,0.057-0.056,0.057h-0.762
		c-0.029,0-0.057-0.028-0.057-0.057l0.021-2.44l-0.021-2.385c0-0.028,0.028-0.056,0.057-0.056h1.87c1.177,0,1.876,0.508,1.876,1.51
		c0,0.755-0.424,1.241-1.137,1.431l1.256,1.948c0.014,0.021,0,0.049-0.027,0.049h-0.897c-0.036,0-0.07-0.021-0.084-0.049
		L18.287,34.264z M17.899,30.976h-1.106l-0.016,1.693h1.144c0.55,0,0.965-0.239,0.965-0.832
		C18.886,31.266,18.528,30.976,17.899,30.976'
				/>
				<path
					className='st0'
					fill='#FFFFFF'
					d='M22.362,32.995l0.007,2.2c0,0.029-0.029,0.058-0.057,0.058h-0.705c-0.029,0-0.057-0.029-0.057-0.058
		l0.021-2.426L21.55,30.37c0-0.027,0.028-0.056,0.057-0.056h0.924c0.036,0,0.07,0.021,0.085,0.049l2.286,3.796l-0.008-0.247
		l-0.028-1.186V30.37c0-0.027,0.028-0.056,0.058-0.056h0.697c0.028,0,0.057,0.029,0.057,0.056l-0.021,2.399l0.021,2.426
		c0,0.029-0.029,0.058-0.057,0.058h-0.96c-0.034,0-0.07-0.022-0.084-0.05l-2.251-3.739l0.014,0.345L22.362,32.995z'
				/>
				<path
					className='st0'
					fill='#FFFFFF'
					d='M27.789,35.253c-0.028,0-0.058-0.028-0.058-0.058l0.03-2.432l-0.03-2.393c0-0.027,0.03-0.055,0.058-0.055
		h0.762c0.029,0,0.056,0.028,0.056,0.055l-0.027,2.393l0.027,2.432c0,0.03-0.027,0.058-0.056,0.058H27.789z'
				/>
				<path
					className='st0'
					fill='#FFFFFF'
					d='M31.216,32.995l0.007,2.2c0,0.029-0.029,0.058-0.058,0.058h-0.704c-0.029,0-0.057-0.029-0.057-0.058
		l0.021-2.426l-0.021-2.399c0-0.027,0.028-0.056,0.057-0.056h0.924c0.036,0,0.07,0.021,0.084,0.049l2.287,3.796l-0.009-0.247
		l-0.027-1.186V30.37c0-0.027,0.027-0.056,0.057-0.056h0.698c0.027,0,0.056,0.029,0.056,0.056l-0.02,2.399l0.02,2.426
		c0,0.029-0.029,0.058-0.056,0.058h-0.96c-0.034,0-0.071-0.022-0.085-0.05l-2.25-3.739l0.014,0.345L31.216,32.995z'
				/>
				<path
					className='st0'
					fill='#FFFFFF'
					d='M38.272,35.351c-1.398,0-2.365-1.03-2.365-2.568c0-1.566,0.967-2.569,2.336-2.569
		c1.165,0,1.926,0.593,2.173,1.63c0.006,0.028-0.014,0.057-0.041,0.057h-0.778c-0.035,0-0.062-0.022-0.071-0.057
		c-0.162-0.62-0.607-0.938-1.275-0.938c-0.912,0-1.448,0.713-1.448,1.891c0,1.143,0.552,1.863,1.476,1.863
		c0.825,0,1.311-0.501,1.334-1.363h-1.362c-0.03,0-0.057-0.028-0.057-0.055v-0.565c0-0.028,0.027-0.056,0.057-0.056h2.221
		c0.028,0,0.057,0.028,0.057,0.056C40.55,34.448,39.745,35.351,38.272,35.351'
				/>
				<rect x='35.494' y='-0.001' className='st1' fill='#FF6600' width='5.104' height='5.052' />
				<path
					className='st0'
					fill='#FFFFFF'
					d='M16.344,25.136h-4.422l-0.215-2.283c-2.033,1.748-3.603,2.532-6.206,2.532c-2.889,0-5.494-1.391-5.494-5.101
		c0-4.065,3.817-5.956,11.416-6.242V13.4c0-1.997-0.97-2.62-3.247-2.782c-3.159-0.225-5.485,0.684-7.166,1.583l0.032-2.997
		C3.478,7.641,6.25,7.017,9.316,7.017c5.138,0,7.028,1.925,7.028,5.777V25.136z M11.202,16.71c-5.108,0.35-6.472,1.435-6.472,3.184
		c0,1.538,0.945,2.273,2.694,2.273c1.468,0,2.552-0.56,3.778-1.399V16.71z'
				/>
				<path
					className='st0'
					fill='#FFFFFF'
					d='M34.303,20.116c-1.035,3.289-3.574,5.399-7.544,5.399c-5.542,0-9.226-3.504-9.226-9.296
		c0-5.542,3.434-9.333,8.975-9.333c4.542,0,6.615,2.109,7.724,5.506l-4.326,1.395c-0.715-2.324-1.539-3.433-3.398-3.433
		c-2.502,0-3.826,1.859-3.826,5.865c0,4.04,1.61,5.864,3.971,5.864c1.964,0,3.039-0.895,3.826-3.112L34.303,20.116z'
				/>
				<rect x='35.493' y='6.899' className='st0' fill='#FFFFFF' width='5.002' height='18.33' />
				<g>
					<path
						className='st2'
						fill='#507293'
						d='M50.011,24.432c0.084,0.144,0.218,0.252,0.402,0.323l-0.156,0.492c-0.288-0.056-0.51-0.164-0.666-0.324
			c-0.156-0.159-0.258-0.403-0.306-0.731c-0.433,0.704-1.084,1.056-1.956,1.056c-0.601,0-1.074-0.17-1.422-0.51
			c-0.349-0.34-0.522-0.794-0.522-1.362c0-0.647,0.23-1.147,0.69-1.5c0.46-0.352,1.098-0.527,1.914-0.527h1.199v-0.612
			c0-0.504-0.111-0.87-0.336-1.098c-0.224-0.229-0.596-0.343-1.115-0.343c-0.48,0-1.049,0.12-1.704,0.36l-0.181-0.528
			c0.704-0.279,1.364-0.42,1.98-0.42c1.368,0,2.052,0.664,2.052,1.992v3.024C49.885,24.053,49.927,24.288,50.011,24.432z
			 M49.188,23.58v-1.728h-1.092c-0.64,0-1.128,0.124-1.464,0.372s-0.504,0.62-0.504,1.115c0,0.433,0.116,0.765,0.348,0.996
			c0.232,0.232,0.557,0.349,0.973,0.349C48.201,24.684,48.781,24.316,49.188,23.58z'
					/>
					<path
						className='st2'
						fill='#507293'
						d='M55.32,25.116h-0.6L54.685,24c-0.496,0.832-1.18,1.248-2.052,1.248c-0.568,0-1.007-0.166-1.314-0.498
			s-0.462-0.806-0.462-1.422V18.84h0.696v4.416c0,0.488,0.102,0.847,0.306,1.074c0.204,0.229,0.514,0.342,0.93,0.342
			c0.729,0,1.34-0.428,1.837-1.283V18.84h0.695V25.116z'
					/>
					<path
						className='st2'
						fill='#507293'
						d='M61.081,16.284v8.832h-0.6l-0.084-0.96c-0.433,0.728-1.061,1.092-1.885,1.092c-0.728,0-1.302-0.29-1.722-0.87
			s-0.63-1.369-0.63-2.37c0-0.647,0.098-1.22,0.294-1.716s0.479-0.884,0.852-1.164c0.372-0.279,0.811-0.42,1.314-0.42
			c0.712,0,1.3,0.284,1.764,0.853V16.2L61.081,16.284z M59.635,24.384c0.276-0.191,0.526-0.483,0.75-0.876v-3.275
			c-0.448-0.632-0.996-0.948-1.644-0.948c-0.568,0-1.019,0.236-1.351,0.708s-0.494,1.14-0.485,2.004
			c0,0.864,0.151,1.526,0.456,1.986c0.304,0.46,0.724,0.689,1.26,0.689C59.021,24.672,59.359,24.576,59.635,24.384z'
					/>
					<path
						className='st2'
						fill='#507293'
						d='M63.085,16.224c0.096,0.096,0.144,0.216,0.144,0.36c0,0.145-0.048,0.264-0.144,0.36
			c-0.096,0.096-0.225,0.144-0.384,0.144c-0.152,0-0.276-0.048-0.372-0.144c-0.097-0.097-0.145-0.216-0.145-0.36
			c0-0.144,0.048-0.264,0.145-0.36c0.096-0.096,0.22-0.144,0.372-0.144C62.86,16.08,62.989,16.128,63.085,16.224z M63.061,18.84
			v6.276h-0.695V18.84H63.061z'
					/>
					<path
						className='st2'
						fill='#507293'
						d='M68.16,24.852c-0.4,0.264-0.84,0.396-1.32,0.396c-0.479,0-0.855-0.142-1.128-0.426
			c-0.271-0.284-0.407-0.694-0.407-1.23v-4.188h-1.057V18.84h1.057v-1.5L66,17.256v1.584h1.62l-0.084,0.564H66v4.152
			c0,0.367,0.076,0.642,0.229,0.821c0.151,0.181,0.384,0.271,0.695,0.271c0.32,0,0.641-0.092,0.96-0.276L68.16,24.852z'
					/>
					<path
						className='st2'
						fill='#507293'
						d='M69.499,24.27c0.107,0.107,0.161,0.242,0.161,0.401c0,0.177-0.06,0.396-0.18,0.66l-0.672,1.596h-0.504
			l0.479-1.764c-0.088-0.048-0.155-0.116-0.203-0.204c-0.049-0.088-0.072-0.184-0.072-0.288c0-0.159,0.056-0.294,0.168-0.401
			c0.111-0.108,0.248-0.162,0.408-0.162C69.253,24.108,69.391,24.162,69.499,24.27z'
					/>
					<path
						className='st2'
						fill='#507293'
						d='M77.46,18.858c0.28,0.1,0.552,0.262,0.816,0.485L77.928,19.8c-0.208-0.168-0.422-0.292-0.642-0.372
			c-0.22-0.079-0.47-0.119-0.75-0.119c-0.592,0-1.064,0.229-1.416,0.689c-0.353,0.46-0.528,1.13-0.528,2.01
			c0,0.872,0.174,1.528,0.522,1.969c0.348,0.439,0.817,0.659,1.41,0.659c0.279,0,0.533-0.043,0.762-0.132
			c0.228-0.088,0.458-0.22,0.69-0.396l0.336,0.468c-0.528,0.448-1.129,0.672-1.801,0.672c-0.536,0-1.004-0.128-1.403-0.384
			c-0.4-0.256-0.71-0.626-0.931-1.11c-0.22-0.483-0.33-1.062-0.33-1.733c0-0.664,0.11-1.246,0.33-1.746
			c0.221-0.5,0.53-0.886,0.931-1.158c0.399-0.271,0.863-0.408,1.392-0.408C76.86,18.708,77.18,18.758,77.46,18.858z'
					/>
					<path
						className='st2'
						fill='#507293'
						d='M81.924,25.14c-0.168,0.487-0.353,0.888-0.553,1.199c-0.199,0.312-0.46,0.57-0.779,0.774
			c-0.32,0.204-0.732,0.354-1.236,0.45l-0.072-0.54c0.416-0.104,0.742-0.232,0.979-0.384c0.235-0.152,0.424-0.343,0.563-0.57
			c0.141-0.229,0.282-0.546,0.426-0.954h-0.239l-2.185-6.276h0.756l1.896,5.761l1.86-5.761h0.731L81.924,25.14z'
					/>
					<path
						className='st2'
						fill='#507293'
						d='M89.028,19.548c0.4,0.561,0.6,1.36,0.6,2.4c0,1.016-0.21,1.82-0.63,2.412s-1.006,0.888-1.758,0.888
			c-0.376,0-0.718-0.084-1.025-0.252c-0.309-0.168-0.566-0.392-0.774-0.672l-0.072,0.792h-0.611v-8.832l0.695-0.084v3.516
			c0.496-0.672,1.124-1.008,1.884-1.008C88.064,18.708,88.628,18.988,89.028,19.548z M88.423,23.982
			c0.308-0.46,0.462-1.138,0.462-2.034c0-1.776-0.564-2.664-1.692-2.664c-0.376,0-0.7,0.094-0.972,0.282
			c-0.272,0.188-0.528,0.454-0.769,0.798v3.3c0.2,0.312,0.44,0.559,0.721,0.738c0.279,0.18,0.592,0.27,0.936,0.27
			C87.677,24.672,88.114,24.442,88.423,23.982z'
					/>
					<path
						className='st2'
						fill='#507293'
						d='M95.136,22.248h-4.188c0.04,0.816,0.234,1.422,0.582,1.818c0.349,0.396,0.806,0.594,1.374,0.594
			c0.328,0,0.618-0.05,0.87-0.15c0.252-0.1,0.526-0.258,0.822-0.474l0.324,0.444c-0.312,0.256-0.633,0.448-0.96,0.576
			c-0.328,0.128-0.692,0.191-1.093,0.191c-0.832,0-1.483-0.284-1.956-0.852c-0.472-0.568-0.708-1.356-0.708-2.364
			c0-0.664,0.103-1.246,0.307-1.746s0.496-0.888,0.876-1.164c0.38-0.275,0.822-0.414,1.326-0.414c0.8,0,1.407,0.271,1.823,0.811
			s0.624,1.282,0.624,2.226C95.159,21.92,95.151,22.088,95.136,22.248z M94.476,21.528c0-0.704-0.146-1.254-0.438-1.65
			c-0.292-0.396-0.726-0.594-1.302-0.594c-0.52,0-0.939,0.2-1.26,0.6c-0.32,0.4-0.496,1.009-0.528,1.824h3.528V21.528z'
					/>
					<path
						className='st2'
						fill='#507293'
						d='M99.18,18.78l-0.132,0.66c-0.176-0.048-0.36-0.072-0.552-0.072c-0.425,0-0.767,0.154-1.026,0.462
			c-0.26,0.309-0.479,0.79-0.654,1.446v3.84H96.12V18.84h0.6l0.06,1.404c0.168-0.52,0.398-0.906,0.69-1.158s0.654-0.378,1.086-0.378
			C98.804,18.708,99.012,18.732,99.18,18.78z'
					/>
					<path
						className='st2'
						fill='#507293'
						d='M99.713,24.27c0.107,0.107,0.161,0.242,0.161,0.401c0,0.177-0.06,0.396-0.18,0.66l-0.672,1.596h-0.504
			l0.479-1.764c-0.088-0.048-0.155-0.116-0.203-0.204c-0.049-0.088-0.072-0.184-0.072-0.288c0-0.159,0.056-0.294,0.168-0.401
			c0.111-0.108,0.248-0.162,0.408-0.162C99.467,24.108,99.604,24.162,99.713,24.27z'
					/>
					<path
						className='st2'
						fill='#507293'
						d='M105.286,16.224c0.096,0.096,0.144,0.216,0.144,0.36c0,0.145-0.048,0.264-0.144,0.36
			c-0.096,0.096-0.225,0.144-0.384,0.144c-0.152,0-0.276-0.048-0.372-0.144c-0.097-0.097-0.145-0.216-0.145-0.36
			c0-0.144,0.048-0.264,0.145-0.36c0.096-0.096,0.22-0.144,0.372-0.144C105.062,16.08,105.19,16.128,105.286,16.224z M105.262,18.84
			v6.276h-0.695V18.84H105.262z'
					/>
					<path
						className='st2'
						fill='#507293'
						d='M110.122,24.852c-0.4,0.264-0.84,0.396-1.32,0.396c-0.479,0-0.855-0.142-1.128-0.426
			c-0.271-0.284-0.407-0.694-0.407-1.23v-4.188h-1.057V18.84h1.057v-1.5l0.695-0.084v1.584h1.62l-0.084,0.564h-1.536v4.152
			c0,0.367,0.076,0.642,0.229,0.821c0.151,0.181,0.384,0.271,0.695,0.271c0.32,0,0.641-0.092,0.96-0.276L110.122,24.852z'
					/>
					<path
						className='st2'
						fill='#507293'
						d='M111.7,24.27c0.107,0.107,0.161,0.242,0.161,0.401c0,0.168-0.054,0.307-0.161,0.414
			c-0.108,0.108-0.246,0.162-0.414,0.162c-0.16,0-0.297-0.056-0.408-0.168c-0.112-0.111-0.168-0.248-0.168-0.408
			c0-0.159,0.056-0.294,0.168-0.401c0.111-0.108,0.248-0.162,0.408-0.162C111.454,24.108,111.592,24.162,111.7,24.27z'
					/>
				</g>
			</g>
			<g></g>
			<g></g>
		</svg>
	)
}
LogoDesk.propTypes = {}
export default LogoDesk
