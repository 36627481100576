import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { H1, P } from '../UI'

const Wrap = styled.div`
	h1 {
		font-weight: 500;
	}
	margin-bottom: 4rem;
`

const ContactInfo = ({ contactInfo }) => {
	const { title, city, state, zip, email, phone, street } = contactInfo
	return (
		<Wrap>
			<H1>{title}</H1>
			<P>{email}</P>
			<P>
				{street}
				<br />
				{city}, {state}, {zip}
				<br />
				{phone}
			</P>
		</Wrap>
	)
}
ContactInfo.propTypes = {
	contactInfo: PropTypes.shape({
		title: PropTypes.string,
	}),
}
export default ContactInfo
