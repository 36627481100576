import React from 'react'
const LQLogo = () => (
	<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 595.16 101.8'>
		<g data-name='Layer 2'>
			<g data-name='Layer 1'>
				<path
					fill='currentColor'
					d='M51,101.8a50.9,50.9,0,1,1,50.86-50.94H96.5C96.5,25.74,76.1,4.17,51,4.17S3.13,25.74,3.13,50.86,23.55,99.72,51,99.72Z'
				/>
				<path
					fill='currentColor'
					d='M51,92.92A41.93,41.93,0,1,1,92.78,50.86H87.46c0-20.1-16.39-37.58-36.49-37.58S12.18,30.76,12.18,50.86,28.75,90.75,51,90.75Z'
				/>
				<path
					fill='currentColor'
					d='M51,83.83a32.91,32.91,0,1,1,32.77-33H78.4C78.4,35.78,66,22.34,51,22.34S21.24,35.78,21.24,50.86,33.63,81.68,51,81.68Z'
				/>
				<path
					fill='currentColor'
					d='M51.49,50.86h1.9c0,25.12,23.83,45.61,49,45.61v5.33A50.94,50.94,0,0,1,51.49,50.86'
				/>
				<path
					fill='currentColor'
					d='M60.47,50.86h2C62.45,71,82.24,87.3,102.34,87.3v5.62A42.08,42.08,0,0,1,60.47,50.86'
				/>
				<path
					fill='currentColor'
					d='M69.48,50.86h2c0,15.14,15.69,27.42,30.84,27.42v5.55a33,33,0,0,1-32.86-33'
				/>
				<polygon
					fill='currentColor'
					points='123.65 80.19 123.65 29.72 129.52 29.72 129.52 74.88 151.78 74.88 151.78 80.19 123.65 80.19'
				/>
				<polygon
					fill='currentColor'
					points='188.48 80.19 160.34 80.19 160.34 29.72 188.48 29.72 188.48 34.93 166.21 34.93 166.21 51.19 187.13 51.19 187.13 56.37 166.21 56.37 166.21 74.94 188.48 74.94 188.48 80.19'
				/>
				<path
					fill='currentColor'
					d='M231.39,80.19l-6.28-16H204.88l-6.21,16h-5.94l20-50.68h4.94l19.85,50.68Zm-8.11-21.33-5.87-15.64q-1.14-3-2.35-7.29a65,65,0,0,1-2.17,7.29L207,58.86Z'
				/>
				<path
					fill='currentColor'
					d='M284.7,54.47q0,12.5-6.79,19.11t-19.52,6.61h-14V29.72h15.46q11.78,0,18.3,6.52t6.53,18.23m-6.22.21q0-9.87-5-14.88t-14.73-5h-8.52V75.12h7.14q10.5,0,15.78-5.16t5.28-15.28'
				/>
				<polygon
					fill='currentColor'
					points='324.09 80.19 295.95 80.19 295.95 29.72 324.09 29.72 324.09 34.93 301.82 34.93 301.82 51.19 322.74 51.19 322.74 56.37 301.82 56.37 301.82 74.94 324.09 74.94 324.09 80.19'
				/>
				<path
					fill='currentColor'
					d='M341.14,59.2v21h-5.87V29.72h13.84q9.3,0,13.73,3.56T367.27,44q0,10-10.15,13.53l13.71,22.68h-6.94l-12.22-21Zm0-5h8q6.22,0,9.12-2.47t2.9-7.4c0-3.34-1-5.74-2.95-7.22s-5.13-2.21-9.48-2.21h-7.63Z'
				/>
				<path
					fill='currentColor'
					d='M422.79,54.89q0,9.69-3.9,16.12a19.5,19.5,0,0,1-11,8.7l12,12.49h-8.53l-9.84-11.39-1.9.07q-11.14,0-17.21-6.82t-6.06-19.24q0-12.33,6.08-19.11t17.26-6.78q10.88,0,17,6.9t6.11,19.06m-40.22,0q0,10.24,4.36,15.55c2.92,3.53,7.14,5.3,12.69,5.3s9.82-1.76,12.67-5.28,4.28-8.71,4.28-15.57S415.15,43,412.31,39.44s-7.05-5.27-12.62-5.27-9.84,1.77-12.76,5.3-4.36,8.67-4.36,15.42'
				/>
				<path
					fill='currentColor'
					d='M472.15,29.72V62.38q0,8.62-5.21,13.56t-14.33,4.94q-9.11,0-14.1-5t-5-13.67V29.72h5.87V62.65q0,6.33,3.45,9.7T453,75.74q6.39,0,9.84-3.4t3.45-9.76V29.72Z'
				/>
				<polygon
					fill='currentColor'
					points='513.65 80.19 485.51 80.19 485.51 29.72 513.65 29.72 513.65 34.93 491.38 34.93 491.38 51.19 512.3 51.19 512.3 56.37 491.38 56.37 491.38 74.94 513.65 74.94 513.65 80.19'
				/>
				<path
					fill='currentColor'
					d='M553.32,66.76a12.36,12.36,0,0,1-4.84,10.39q-4.83,3.73-13.12,3.73-9,0-13.8-2.31V72.91A36.87,36.87,0,0,0,528.32,75a36.09,36.09,0,0,0,7.25.76q5.86,0,8.84-2.23a7.3,7.3,0,0,0,3-6.2,8,8,0,0,0-1-4.29,9.78,9.78,0,0,0-3.53-3.09,47.57,47.57,0,0,0-7.5-3.21q-7-2.52-10.07-6a13.19,13.19,0,0,1-3-9,11.18,11.18,0,0,1,4.39-9.28Q531,29,538.19,29A34.22,34.22,0,0,1,552,31.76l-1.83,5.11a31.31,31.31,0,0,0-12.15-2.63,11.81,11.81,0,0,0-7.29,2,6.6,6.6,0,0,0-2.62,5.56,8.48,8.48,0,0,0,1,4.3,9,9,0,0,0,3.26,3.07,40.1,40.1,0,0,0,7,3.09q8,2.84,10.93,6.08a12,12,0,0,1,3,8.42'
				/>
				<polygon
					fill='currentColor'
					points='579.17 80.19 573.3 80.19 573.3 34.93 557.32 34.93 557.32 29.72 595.16 29.72 595.16 34.93 579.17 34.93 579.17 80.19'
				/>
			</g>
		</g>
	</svg>
)
export default LQLogo
